import React, {useEffect} from "react";

import "./App.css";
import { ZoomMtg } from "@zoomus/websdk";

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.3.5/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const userNameParam = urlParams.get("n");
  const userEmailParam = urlParams.get("e");
  const meetingNumberParam = urlParams.get("mid");
  const meetingPasswordParam = urlParams.get("pwd");
  const langParam = urlParams.get("lang");

  ZoomMtg.i18n.load(langParam ?? "en-US");
  ZoomMtg.i18n.reload(langParam ?? "en-US");

  var signatureEndpoint = process.env.REACT_APP_SIGNATURE_ENDPOINT_URL;
  var apiKey = process.env.REACT_APP_ZOOM_JWT_API_KEY;
  var meetingNumber = meetingNumberParam;
  var role = 0;
  var leaveUrl = window.location.href;
  var userName = userNameParam;
  var userEmail = userEmailParam;
  var passWord = meetingPasswordParam;
  var registrantToken = "";

  useEffect(() => {
    getSignature();
  })

  function getSignature() {
    fetch(signatureEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        startMeeting(response.signature);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function startMeeting(signature) {
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      disableInvite: true,//Enable or disable invite function.
      showMeetingHeader: false, //Show or hide the meeting header, includes meeting number and topic
      success: (success) => {
        console.log(success);

        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          apiKey,
          userEmail: userEmail,
          passWord: passWord,
          tk: registrantToken,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  return (
    <div className="App">
    </div>
  );
}

export default App;
